import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    monthlyId: Number,
    yearlyId: Number,
  };

  static targets = [
    "annualLabel",
    "monthlyLabel",
    "toggle",
    "price",
    "upgradeButton",
    "buyButton",
    "checkout",
    "annualPriceLabel",
  ];

  connect() {
    this.togglePrice();
  }

  togglePrice() {
    const is_annual = this.toggleTarget.checked;

    if (is_annual) {
      this.checkoutTarget.dataset.checkoutProductValue = this.yearlyIdValue;

      this.annualLabelTarget.classList.remove("text-gray-400");
      this.annualLabelTarget.classList.add("text-gray-700");

      this.monthlyLabelTarget.classList.remove("text-gray-700");
      this.monthlyLabelTarget.classList.add("text-gray-400");

      this.annualPriceLabelTarget.classList.remove("hidden");

      this.priceTarget.innerHTML = "$6";
    } else {
      this.checkoutTarget.dataset.checkoutProductValue = this.monthlyIdValue;

      this.monthlyLabelTarget.classList.remove("text-gray-400");
      this.monthlyLabelTarget.classList.add("text-gray-700");

      this.annualLabelTarget.classList.remove("text-gray-700");
      this.annualLabelTarget.classList.add("text-gray-400");

      this.annualPriceLabelTarget.classList.add("hidden");

      this.priceTarget.innerHTML = "$8";
    }
  }

  setDefaultLabels() {
    this.upgradeButtonTarget.innerHTML = "Upgrade!";
    this.buyButtonTarget.innerHTML = "Buy one time!";
  }

  upgrade() {
    const svg =
      '<svg class="animate-spin w-4 h-4 mr-2 text-white-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
    this.upgradeButtonTarget.innerHTML = svg + " Loading...";

    setTimeout(() => {
      this.setDefaultLabels();
    }, 3000);
  }

  buy() {
    const svg =
      '<svg class="animate-spin w-4 h-4 mr-2 text-white-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
    this.buyButtonTarget.innerHTML = svg + " Loading...";

    setTimeout(() => {
      this.setDefaultLabels();
    }, 3000);
  }
}
