import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["tab1", "tab2", "tab1Link", "tab2Link"]

  showTab1(event) {
    event.preventDefault()

    this.tab2LinkTarget.classList.remove("border-primary")
    this.tab2LinkTarget.classList.remove("text-primary")
    this.tab2LinkTarget.classList.add("text-gray-600")

    this.tab1LinkTarget.classList.add("border-primary")
    this.tab1LinkTarget.classList.add("text-primary")
    this.tab1LinkTarget.classList.remove("text-gray-600")

    this.tab2Target.classList.add("hidden")
    this.tab1Target.classList.remove("hidden")
  }

  showTab2(event) {
    event.preventDefault()

    this.tab1LinkTarget.classList.remove("border-primary")
    this.tab1LinkTarget.classList.remove("text-primary")
    this.tab1LinkTarget.classList.add("text-gray-600")

    this.tab2LinkTarget.classList.add("border-primary")
    this.tab2LinkTarget.classList.add("text-primary")
    this.tab2LinkTarget.classList.remove("text-gray-600")

    this.tab1Target.classList.add("hidden")
    this.tab2Target.classList.remove("hidden")
  }
}
