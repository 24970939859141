import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["results", "youtubeSearch", "youtubeTitle"]

  initialize() {
    this.searchChannel = this.searchChannel.bind(this)

    this.youtubeSearchTarget.addEventListener(
      "input",
      this.delay(this.searchChannel, 1000)
    )
  }

  setLoading(e) {
    const loadingMessage = document.createElement("div");
    loadingMessage.innerHTML = `<span>Loading...</span>`;
    loadingMessage.classList.add(
      "text-primary-black",
      "cursor-default",
      "p-2"
    );

    if(!e.inputType && this.youtubeSearchTarget.value === ''){
      this.resultsTarget.classList.add("hidden")
      return
    }

    const query = e.target.value
    if (query.length < 3) {
      return
    }
    this.resultsTarget.classList.remove("hidden")
    this.resultsTarget.replaceChildren(loadingMessage)
  }

  async channelSearchRequest(query) {
    try {
      const source = await fetch(`/youtube_search?q=${query}`)
      const data = await source.json()

      return data
    } catch (error) {
      return error
    }
  }

  delay(fn, ms) {
    let timer = 0
    return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
  }

  async searchChannel(e) {
    const query = e.target.value
    if (query.length < 3) {
      return
    }
    const results = await this.channelSearchRequest(query)

    if (!results.length) {
      const message = document.createElement("div")
      message.innerHTML = `<span>Found No Results for "${query}"</span>`
      message.classList.add(
        "text-primary-black",
        "cursor-default",
        "p-2"
      );

      this.resultsTarget.replaceChildren(message)
    } else {
      const resultsHtml = document.createElement("ul")

      results.forEach((element) => {
        const resultItem = document.createElement("li")
        resultItem.innerText = element.title
        resultItem.setAttribute(
          "data-action",
          "click->youtube-auto-complete#setValue"
        )
        resultItem.setAttribute("data-channel", element.id)
        resultItem.setAttribute("data-title", element.title)
        resultItem.classList.add(
          "text-primary-black",
          "cursor-default",
          "select-none",
          "relative",
          "py-2",
          "pl-3",
          "pr-9",
          "hover:text-white",
          "hover:bg-primary-dark"
        )
        resultsHtml.append(resultItem)
      })

      this.resultsTarget.replaceChildren(resultsHtml)
    }
  }

  setValue(e) {
    const youtubeUrl = `https://www.youtube.com/channel/${e.target.dataset.channel}`
    this.youtubeSearchTarget.value = youtubeUrl
    this.youtubeTitleTarget.value = e.target.dataset.title
    this.resultsTarget.innerHTML = ""

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }
}
