import { Controller } from "stimulus"
import { numFormatter } from "../shared/utils"
import RedditIcon from "../images/source-icons/reddit-icon"
export default class extends Controller {
  static targets = ["results", "redditSearch", "redditTitle"]

  initialize() {
    this.searchSubreddit = this.searchSubreddit.bind(this)

    this.redditSearchTarget.addEventListener("input", this.delay(this.searchSubreddit, 1000))
  }

  setLoading(e) {
    const loadingMessage = document.createElement("div")
    loadingMessage.innerHTML = `<span>Loading...</span>`
    loadingMessage.classList.add("text-primary-black", "cursor-default", "p-2")

    if (!e.inputType && this.redditSearchTarget.value === "") {
      this.resultsTarget.classList.add("hidden")
      return
    }

    const query = e.target.value
    if (query.length < 3) {
      return
    }
    this.resultsTarget.classList.remove("hidden")
    this.resultsTarget.replaceChildren(loadingMessage)
  }

  async subredditsRequest(query) {
    try {
      const source = await fetch(`/reddit_sources_search?q=${query}`)
      const data = await source.json()

      return data
    } catch (error) {
      return error
    }
  }

  delay(fn, ms) {
    let timer = 0
    return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
  }

  async searchSubreddit(e) {
    const query = e.target.value
    if (query.length < 3) {
      return
    }
    const results = await this.subredditsRequest(query)

    if (!results.length) {
      const message = document.createElement("div")
      message.innerHTML = `<span>Found No Results for "${query}"</span>`
      message.classList.add("text-primary-black", "cursor-default", "p-2")

      this.resultsTarget.replaceChildren(message)
    } else {
      const resultsHtml = document.createElement("ul")

      results.forEach((element) => {
        const resultItem = document.createElement("li")
        const container = document.createElement("div")
        const img = element.icon
          ? `<img src=${element.icon} class="w-5 rounded-full align-middle mr-2" />`
          : `<img src=${RedditIcon} class="w-5 rounded-full align-middle mr-2" />`

        container.innerHTML = `
        <a class="flex items-center block text-black group-hover:text-white">
          ${img}
          <div>${element.subreddit}
            <span class="text-xs font-normal group-hover:text-white text-gray-500 ml-2">${numFormatter(
              element.subscribers
            )} subscribers</span>
          </div>
        </a>
        <div class="mt-2 font-normal">${element.title}</div>
        `

        container.setAttribute("data-action", "click->reddit-auto-complete#setValue")
        container.setAttribute("data-subreddit", element.url)
        container.setAttribute("data-title", element.subreddit)
        container.classList.add(
          "text-primary-black",
          "cursor-default",
          "select-none",
          "relative",
          "py-2",
          "pl-3",
          "pr-9",
          "group",
          "hover:text-white",
          "hover:bg-primary-dark",
          "border-b"
        )
        resultItem.append(container)
        resultsHtml.append(resultItem)
      })

      this.resultsTarget.replaceChildren(resultsHtml)
    }
  }

  setValue(e) {
    this.redditSearchTarget.value = e.currentTarget.dataset.subreddit
    this.redditTitleTarget.value = e.currentTarget.dataset.title
    this.resultsTarget.innerHTML = ""

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }
}
