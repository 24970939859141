import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    product: Number,
    oneTimeProduct: Number,
    email: String,
    updateUrl: String,
    cancelUrl: String,
  };
  static targets = [
    "pricingContainer",
    "successAlertContainer",
    "successMethodAlertContainer",
    "successCancelAlertContainer",
  ];

  open(e) {
    e.preventDefault();

    Paddle.Checkout.open({
      product: this.productValue,
      email: this.emailValue,
      disableLogout: true,
      successCallback: () => {
        this.successAlertContainerTarget.classList.remove("hidden");
        this.pricingContainerTarget.classList.add("hidden");
      },
    });
  }

  openBuy(e) {
    e.preventDefault();

    Paddle.Checkout.open({
      product: this.oneTimeProductValue,
      email: this.emailValue,
      allowQuantity: false,
      disableLogout: true,
      successCallback: () => {
        this.successAlertContainerTarget.classList.remove("hidden");
        this.pricingContainerTarget.classList.add("hidden");
      },
    });
  }

  update(e) {
    e.preventDefault();

    Paddle.Checkout.open({
      override: this.updateUrlValue,
      successCallback: () => {
        this.successMethodAlertContainerTarget.classList.remove("hidden");
      },
    });
  }

  cancel(e) {
    e.preventDefault();

    Paddle.Checkout.open({
      override: this.cancelUrlValue,
      successCallback: () => {
        this.successCancelAlertContainerTarget.classList.remove("hidden");
      },
    });
  }
}
