import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selector"]

  connect() {
    const timeZones = [...this.selectorTarget.options].map((o) => o.value)
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timeZones.includes(currentTimeZone)) {
      this.selectorTarget.value = currentTimeZone
    }
  }
}
