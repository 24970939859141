import { Controller } from "stimulus";
import { City, Country, State } from "country-state-city";

export default class extends Controller {
  static targets = ["results", "weatherSearch", "title", "lat", "lon"];

  initialize() {
    this.searchCity = this.searchCity.bind(this);

    this.weatherSearchTarget.addEventListener(
      "input",
      this.delay(this.searchCity, 1000)
    );
  }

  setLoading(e) {
    const loadingMessage = document.createElement("div");
    loadingMessage.innerHTML = `<span>Loading...</span>`;
    loadingMessage.classList.add(
      "text-primary-black",
      "cursor-default",
      "p-2"
    );

    if(!e.inputType && this.weatherSearchTarget.value === ''){
      this.resultsTarget.classList.add("hidden")
      return
    }

    const query = e.target.value;
    if (query.length < 3) {
      return;
    }
    this.resultsTarget.classList.remove("hidden")
    this.resultsTarget.replaceChildren(loadingMessage);
  }

  delay(fn, ms) {
    let timer = 0;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(fn.bind(this, ...args), ms || 0);
    };
  }

  searchCity(e) {
    const cities = City.getAllCities();
    const query = e.target.value.toLowerCase();
    const results = cities.filter((city) =>
      city.name.toLowerCase().match(query)
    );

    if (!results.length) {
      const message = document.createElement("div");
      message.innerHTML = `<span>Found No Results for "${query}"</span>`;
      message.classList.add(
        "text-primary-black",
        "cursor-default",
        "p-2"
      );

      this.resultsTarget.replaceChildren(message);
    } else {
      const resultsHtml = document.createElement("ul");

      results.slice(0, 20).forEach((element) => {
        const resultItem = document.createElement("li");
        const country = Country.getCountryByCode(element.countryCode);
        const state = State.getStateByCodeAndCountry(element.stateCode, element.countryCode)

        resultItem.innerText = `${element.name} (${state.name}) - ${country.name} ${country.flag}`;
        resultItem.setAttribute(
          "data-action",
          "click->weather-auto-complete#setCityValue"
        );
        resultItem.setAttribute("data-name", element.name);
        resultItem.setAttribute("data-lat", element.latitude);
        resultItem.setAttribute("data-lon", element.longitude);
        resultItem.classList.add(
          "text-primary-black",
          "cursor-default",
          "select-none",
          "relative",
          "py-2",
          "pl-3",
          "pr-9",
          "hover:text-white",
          "hover:bg-primary"
        );
        resultsHtml.append(resultItem);
      });

      this.resultsTarget.replaceChildren(resultsHtml);
    }
  }

  setCityValue(e) {
    this.weatherSearchTarget.value = e.target.dataset.name;
    this.latTarget.value = e.target.dataset.lat;
    this.lonTarget.value = e.target.dataset.lon;
    this.resultsTarget.innerHTML = "";

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }
}
