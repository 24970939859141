import { Controller } from "stimulus"
import { numFormatter } from "../shared/utils"

export default class extends Controller {
  static targets = ["result", "input", "twitterTitle", "checkButton"]

  async userRequest(query) {
    try {
      const source = await fetch(`/twitter_search?q=${query}`)
      const data = await source.json()

      return data
    } catch (error) {
      return error
    }
  }

  async lookupUser(e) {
    e.preventDefault()

    const query = this.inputTarget.value
    const userHandle = query[0] == "@" ? query.substring(1) : query
    this.checkButtonTarget.textContent = "Checking..."

    const results = await this.userRequest(userHandle)
    this.checkButtonTarget.textContent = "Check"

    if (!results || results.errors) {
      const message = document.createElement("div")
      message.innerHTML = `<span>Found No Results for "${query}"</span>`
      message.classList.add("text-primary-black", "cursor-default", "p-2")

      this.resultTarget.replaceChildren(message)
    } else {
      const resultsHtml = document.createElement("div")

      results.forEach((element) => {
        const resultItem = document.createElement("div")

        const image = document.createElement("img")
        image.src = element.profile_image_url.replace("_normal", "_x96")
        image.alt = `${element.username}'s profile image`
        image.classList.add(
          "w-12",
          "rounded-full",
          "align-middle",
          "mr-2",
          "pointer-events-none"
        )

        const header = document.createElement("div")
        header.classList.add(
          "inline-block",
          "border-box",
          "pointer-events-none"
        )

        const name = document.createElement("span")
        name.innerText = `${element.name}`
        name.classList.add(
          "margin-0",
          "font-bold",
          "block",
          "pointer-events-none"
        )

        const username = document.createElement("span")
        username.innerText = `@${element.username}`
        username.classList.add(
          "margin-0",
          "opacity-50",
          "block",
          "font-normal",
          "pointer-events-none"
        )

        const description = document.createElement("span")
        description.innerText = `${element.description}`
        description.classList.add(
          "block",
          "font-normal",
          "w-full",
          "mt-2",
          "pointer-events-none"
        )

        const followings = document.createElement("div")
        followings.classList.add(
          "flex",
          "border-box",
          "pointer-events-none",
          "mt-2"
        )

        const followersCount = document.createElement("span")
        followersCount.classList.add(
          "margin-0",
          "font-normal",
          "block",
          "pointer-events-none",
          "mr-4"
        )
        followersCount.innerHTML =
          "<strong>" +
          numFormatter(element.public_metrics.followers_count) +
          "</strong> Followers"

        const followingCount = document.createElement("span")
        followingCount.classList.add(
          "margin-0",
          "font-normal",
          "block",
          "pointer-events-none"
        )
        followingCount.innerHTML =
          "<strong>" +
          numFormatter(element.public_metrics.following_count) +
          "</strong> Following"

        header.appendChild(name)
        header.appendChild(username)

        followings.appendChild(followersCount)
        followings.appendChild(followingCount)

        resultItem.appendChild(image)
        resultItem.appendChild(header)
        resultItem.appendChild(description)
        resultItem.appendChild(followings)

        resultItem.setAttribute("data-action", "click->twitter-lookup#setValue")
        resultItem.setAttribute(
          "data-url",
          `https://twitter.com/${element.username}`
        )
        resultItem.setAttribute("data-name", element.name)
        resultItem.classList.add(
          "text-primary-black",
          "cursor-default",
          "select-none",
          "flex",
          "flex-wrap",
          "p-3",
          "hover:text-white",
          "hover:bg-primary-dark"
        )
        resultsHtml.append(resultItem)
      })

      this.resultTarget.replaceChildren(resultsHtml)
    }
  }

  setValue(e) {
    this.inputTarget.value = e.target.dataset.url
    this.twitterTitleTarget.value = e.target.dataset.name
    this.resultTarget.innerHTML = ""

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }
}
