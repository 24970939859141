import Sortable from "stimulus-sortable"

export default class extends Sortable {
  connect() {
    super.connect()
  }

  async end ({ item, newIndex }) {
    await super.end({ item, newIndex })
    location.reload();
  }
}
