import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "source",
    "input",
    "exchangeRate",
    "rss",
    "weather",
    "reddit",
    "turbo",
    "youtube",
    "twitter",
    "hackerNews",
    "label",
    "newsletter",
  ]

  showForm(event) {
    const sourceType = event.target.value

    this.labelTargets.forEach((el) => el.classList.remove("border-primary-dark"))
    event.target.parentElement.classList.add("border-primary-dark")

    switch (sourceType) {
      case "ExchangeRate":
        this.showRateForm()
        break
      case "Rss":
        this.showRssForm()
        break
      case "Weather":
        this.showWeatherForm()
        break
      case "Reddit":
        this.showRedditForm()
        break
      case "Youtube":
        this.showYoutubeForm()
        break
      case "Twitter":
        this.showTwitterForm()
        break
      case "HackerNews":
        this.showHackerNewsForm()
        break
      case "Newsletter":
        this.showNewsletterForm()
        break
      default:
        break
    }
  }

  hideAllForms() {
    this.sourceTargets.forEach((el) => {
      el.classList.add("hidden")
      this.inputTargets.forEach((el) => {
        el.value = ""
        el.disabled = true
      })
    })
  }

  showRateForm() {
    this.hideAllForms()
    this.exchangeRateTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.exchangeRateTarget)
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  showRssForm() {
    this.hideAllForms()
    this.rssTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.rssTarget)

    this.rssTarget.querySelector('div [data-rss-form-target="rssMaxEntriesInput"]').value = 5
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  showWeatherForm() {
    this.hideAllForms()
    this.weatherTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.weatherTarget)
  }

  showRedditForm() {
    this.hideAllForms()
    this.redditTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.redditTarget)

    this.redditTarget.querySelector('div [data-reddit-form-target="redditMaxEntriesInput"]').value = 5
    this.redditTarget.querySelector('div [data-source-form-period-target="redditPeriodInput"]').value = "last_sent"
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  showYoutubeForm() {
    this.hideAllForms()
    this.youtubeTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.youtubeTarget)
  }

  showTwitterForm() {
    this.hideAllForms()
    this.twitterTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.twitterTarget)

    this.twitterTarget.querySelector('div [data-twitter-form-target="twitterMaxEntriesInput"]').value = 10
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  showHackerNewsForm() {
    this.hideAllForms()
    this.hackerNewsTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.hackerNewsTarget)

    this.hackerNewsTarget.querySelector('div [data-hacker-news-form-target="hnMaxEntriesInput"]').value = 5
    this.hackerNewsTarget.querySelector('div [data-source-form-period-target="hnPeriodInput"]').value = "last_sent"
    this.hackerNewsTarget.querySelector('div [data-hacker-news-form-target="hnTitle"]').value =
      "Hacker News - Top stories"
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  showNewsletterForm() {
    this.hideAllForms()
    this.newsletterTarget.classList.remove("hidden")
    this.removeDisabledAttribute(this.newsletterTarget)

    this.newsletterTarget.querySelector('div [data-source-form-target="input"]').value = "Newsletters"
    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }

  removeDisabledAttribute(element) {
    element
      .querySelectorAll('div [data-source-form-target="input"]')
      .forEach((el) => el.removeAttribute("disabled"))

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = true
  }
}
