import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["results", "search", "title", "lat", "lon"];

  initialize() {
    this.search = this.search.bind(this);

    this.searchTarget.addEventListener("input", this.delay(this.search, 1000));
  }

  setLoading(e) {
    const loadingMessage = document.createElement("div");
    loadingMessage.innerHTML = `<span>Loading...</span>`;
    loadingMessage.classList.add(
      "text-primary-black",
      "cursor-default",
      "p-2"
    );

    if(!e.inputType && this.searchTarget.value === ''){
      this.resultsTarget.classList.add("hidden")
      return
    }

    const query = e.target.value;
    if (query.length < 3) {
      return;
    }
    this.resultsTarget.classList.remove("hidden")
    this.resultsTarget.replaceChildren(loadingMessage);
  }

  delay(fn, ms) {
    let timer = 0;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(fn.bind(this, ...args), ms || 0);
    };
  }

  async sourcesRequest(query) {
    try {
      const source = await fetch(`/rss_sources_search?q=${query}`);
      const data = await source.json();

      return data;
    } catch (error) {
      return error;
    }
  }

  async search(e) {
    const query = e.target.value;
    if (query.length < 3) {
      return;
    }
    const results = await this.sourcesRequest(query);

    if (!results.length) {
      const notFoundMessage = document.createElement("div");
      notFoundMessage.innerHTML = `<span>Found No Results for "${query}"</span>`;
      notFoundMessage.classList.add(
        "text-primary-black",
        "cursor-default",
        "p-2"
      );

      const resultsHtml = document.createElement("ul");
      resultsHtml.append(notFoundMessage);

      const manualResultItem = document.createElement("li");
      manualResultItem.innerText = "Click here to add this source manually";
      manualResultItem.setAttribute("data-action", "click->auto-complete#setValue");
      manualResultItem.setAttribute("data-feed-url", query);
      manualResultItem.setAttribute("data-title", query);
      manualResultItem.classList.add(
        "text-primary-black",
        "cursor-default",
        "select-none",
        "block",
        "p-2",
        "hover:text-white",
        "hover:bg-primary"
      );
      resultsHtml.append(manualResultItem);
      this.resultsTarget.replaceChildren(resultsHtml);
    } else {
      const resultsHtml = document.createElement("ul");

      results.forEach((element) => {
        const resultItem = document.createElement("li");
        resultItem.innerText = element.title;
        resultItem.setAttribute("data-action", "click->auto-complete#setValue");
        resultItem.setAttribute("data-feed-url", element.feed_url);
        resultItem.setAttribute("data-title", element.title);
        resultItem.classList.add(
          "text-primary-black",
          "cursor-default",
          "select-none",
          "block",
          "py-2",
          "pl-3",
          "pr-9",
          "hover:text-white",
          "hover:bg-primary"
        );
        resultsHtml.append(resultItem);
      });

      this.resultsTarget.replaceChildren(resultsHtml);
    }
  }

  setValue(e) {
    this.searchTarget.value = e.target.dataset.feedUrl;
    this.titleTarget.value = e.target.dataset.title;
    this.resultsTarget.innerHTML = "";

    document.querySelectorAll('input[data-source-form-target="save"]')[0].disabled = false
  }
}
