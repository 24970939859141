// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
require.context("../images", true);

import Autosave from "stimulus-rails-autosave";
import ContentLoader from "stimulus-content-loader";
import Sortable from "stimulus-sortable";
import Dropdown from "stimulus-dropdown"
import Clipboard from 'stimulus-clipboard'

Rails.start();
ActiveStorage.start();

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
application.register("autosave", Autosave);
application.register("content-loader", ContentLoader);
application.register("sortable", Sortable);
application.register("dropdown", Dropdown)
application.register('clipboard', Clipboard)
