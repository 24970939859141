import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["element"]

  close(event) {
    event.preventDefault()

    this.elementTarget.remove()
  }
}
